import React, {ReactNode} from "react";
import {Link} from "gatsby";
import {Box, Flex, NavLink, Link as ThemedLink} from "theme-ui";

import Logo from "./Logo";
import AddListingButton from "./AddListingButton";

import "./layout.css";

type LayoutProps = {
  children: ReactNode,
  pageContext: any // TODO: could be typed, with the help of gatsby?
}

export default ({ children, pageContext }: LayoutProps): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }}>
    <Box as='header'
      sx={{
        width: '100%',
      }}>
      <Flex as="nav" sx={{
        maxWidth: 768,
        mx: 'auto',
      }}>
        <Flex sx={{
          justifyContent: 'space-between',
          width: '100%'
        }}>
          <Flex sx={{ alignItems: 'center' }}>
            <NavLink as={Link} to='/' sx={{ p: '2', display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '2' }}>
              <Logo />&nbsp;Home
            </NavLink>
            <NavLink as={Link} to='/help' p={2}>
              Help
            </NavLink>
            <NavLink as={Link} to='/about' p={2}>
              About
            </NavLink>
          </Flex>
          <Box sx={{ display: 'flex', placeItems: 'center', px: '2' }}>
            <AddListingButton />
          </Box>
        </Flex>
      </Flex>

    </Box>
    <Box as='main'
      sx={{
        width: '100%',
        flex: '1 1 auto',
      }}>
      <Box as='div'
        sx={{
          maxWidth: 768,
          mx: 'auto',
          px: 2,
        }}>
        {children}
      </Box>
    </Box>
    <Box as='footer'
      sx={{
        width: '100%',
        maxWidth: 768,
        mx: 'auto',
      }}>
      <Box as='div' sx={{
        maxWidth: 768,
        p: 2,
      }}>
        &copy; 2023 <ThemedLink as="a" href="https://www.uber5.com">Uber5 Pty</ThemedLink>, Cape Town.
      </Box>
    </Box>
  </Box>
)

export const Head = ({ pageContext }) => {
  return <>
    { pageContext?.frontmatter?.title && <title>{pageContext.frontmatter.title}</title> }
  </>;
}

